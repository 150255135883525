import React from 'react'
import Emoji from 'a11y-react-emoji'

import './ServiceCard.scss'

class DummyServiceCard extends React.Component {

  render() {
    return (
      <article className="ServiceCardContainer">

        <div
          title={this.props.title}
          className={`ServiceCard`}
        >

            {this.props.icon.length > 0 ? (
              <Emoji
                className="ServiceCard__icon"
                label={this.props.title}
                symbol={this.props.icon}
              />
            ) :
              <img
                className="ServiceCard__icon"
                src={this.props.iconPath}
                alt={`${this.props.title} logo`}
                style={{maxWidth: `2rem`}}
              />
            }

          <h3 className="ServiceCard__title">
            {this.props.title}&mdash;
            <span
              className="o-50"
              dangerouslySetInnerHTML={{__html: this.props.description}}
            />
          </h3>

        </div>

      </article>
    );
  }
}

export default DummyServiceCard
