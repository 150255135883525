import React       from 'react'
import Helmet      from 'react-helmet'
import AnchorLink  from 'react-anchor-link-smooth-scroll'
import { Link }         from 'gatsby'

import config      from '../../../data/site-config'

import Layout      from '../../components/layout'
import MainWrapper from '../../components/MainWrapper/MainWrapper'
import PageHero    from '../../components/PageHero'
import ServiceCard from '../../components/Services/ServiceCard/ServiceCard'
import DummyServiceCard from '../../components/Services/ServiceCard/DummyServiceCard'

const ServicesPage = () => (
  <Layout>
    <Helmet
      title={`🛠 Services • ${config.siteTitle}`}
      meta={[
        { name: 'description', content: 'Spacetime provides professional web based services such as design, ux, ui, WordPress development and hosting, seo, ecommerce, Shopify, Kickstarter' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/services/` }
      ]}
    />

    <PageHero>
      <h1 className="t-headline">A very particular set of skills&mdash;<span className="t-headline__alt">acquired over a very long career</span></h1>
    </PageHero>

    <MainWrapper>

      <section className="bg-near-white pv5 pv6-ns">
        <div className="row">
          <h2 className="mw6 center">A design-led agency focused on Product Development.</h2>
          <p className="mw6 center">At Spacetime we take on products from inception to launch, from business model to people's pocket, from wireframe to continuous deployment.</p>
          <p className="mw6 center">We pride ourselves in loving what we do as we take on complex problems and come up with elegant and simple solutions.</p>
          <div className="bg-white pa3 pa4-m pa5-l mw7 center mt5">
            <span className="t-small-caps black-30 mb4 dib">Core Services</span>
            <ol className="cc-2-ns small-caps-ordered-list pa0 ma0">
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#strategy">Strategy</AnchorLink></li>
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#design">Design</AnchorLink></li>
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#development">Development</AnchorLink></li>
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#seo">SEO</AnchorLink></li>
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#leadership">Leadership</AnchorLink></li>
              <li className=""><AnchorLink className="f3 f4-m f2-l fw7 black-80 hover-red mv2 ml2 ml3-l dib" href="#startups">Startups</AnchorLink></li>
            </ol>
          </div>
        </div>
      </section>

      <section id="strategy" className="ServiceSection row">

        <span className="small-caps fw8 red">01</span>
        <h3 className="f2 f1-ns fw7 mt2">Strategy</h3>

        <div className="ServiceSection__grid">
          <ServiceCard
            name={`coffee-time`}
            title={`Coffee Time`}
            description={`business strategy and insight over a cup of coffee`}
            icon={`☕️`}
            iconPath={``}
            bgColor={`#c0b282`} />

          <ServiceCard
            name={`cto`}
            title={`CTO As A Service`}
            description={`hire technical leadership to grow your business`}
            icon={`💎`}
            iconPath={``}
            bgColor={`#EA5500`} />

          <ServiceCard
            name={`strategy-retainer`}
            title={`Strategy Retainer`}
            description={`strategic and tactical expertise for growth hacking`}
            icon={`🎯`}
            iconPath={``}
            bgColor={`#5A62FF`} />

          <DummyServiceCard
            title={`Reputation Management`}
            description={`protect your online brand reputation`}
            icon={`✨`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section id="design" className="ServiceSection row">

      <span className="small-caps fw8 red">02</span>
      <h3 className="f2 f1-ns fw7 mt2">Design</h3>

        <div className="ServiceSection__grid">

          <ServiceCard
            name={`design`}
            title={`Design Services`}
            description={`let us be your professional design team`}
            icon={`🎨`}
            iconPath={``}
            bgColor={`#cd96e5`} />

          <DummyServiceCard
            title={`Design Systems`}
            description={`build a cohesive design ecosystem`}
            icon={`🍣`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`UX Design`}
            description={`give your customers the best experience possible`}
            icon={`🎡`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`Branding`}
            description={`craft the identity behind the logo and business`}
            icon={`🌈`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`UI Design`}
            description={`design a unique, compelling user interface`}
            icon={`💄`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`Web Design`}
            description={`stand out from the crowd with stellar website design`}
            icon={`🖥`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section id="development" className="ServiceSection row">

      <span className="small-caps fw8 red">03</span>
      <h3 className="f2 f1-ns fw7 mt2">Development</h3>

        <div className="ServiceSection__grid">

          <ServiceCard
            name={`websites`}
            title={`Websites`}
            description={`develop high quality website experiences`}
            icon={`🕸`}
            iconPath={``}
            bgColor={`#1DE9B6`} />

          <ServiceCard
            name={`wordpress-hosting`}
            title={`WordPress Hosting`}
            description={`blazing fast, incredibly secure managed WP`}
            icon={``}
            iconPath={`/icons/wordpress-logo.svg`}
            bgColor={`#21759b`} />

          <DummyServiceCard
            title={`Responsive Web Design`}
            description={`optimal cross-device website design`}
            icon={`🏗`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`Mobile First`}
            description={`enhance mobile device usability and user experience`}
            icon={`📱`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`Shopify`}
            description={`eCommerce growth to increase sales and conversions`}
            icon={`🛒`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section id="seo" className="ServiceSection row">

      <span className="small-caps fw8 red">04</span>
      <h3 className="f2 f1-ns fw7 mt2">SEO</h3>

        <div className="ServiceSection__grid">
          <ServiceCard
            name={`technical-seo`}
            title={`Technical SEO`}
            description={`for a lean mean search engine optimization machine`}
            icon={`🔍`}
            iconPath={``}
            bgColor={`#86fc9e`} />

          <DummyServiceCard
            title={`Monthly SEO Report`}
            description={`win big with on-going search engine optimization`}
            icon={`📊`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`Local SEO`}
            description={`grow your local business organic search traffic`}
            icon={`🗺`}
            iconPath={``}
            bgColor={`#ccc`} />

          <DummyServiceCard
            title={`SEO Audit`}
            description={`one time SEO investigation with actionable insights`}
            icon={`🏁`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section id="leadership" className="ServiceSection row">

      <span className="small-caps fw8 red">05</span>
      <h3 className="f2 f1-ns fw7 mt2">Leadership</h3>

        <div className="ServiceSection__grid">
          <ServiceCard
            name={`cto`}
            title={`CTO As A Service`}
            description={`hire technical leadership to grow your business`}
            icon={`💎`}
            iconPath={``}
            bgColor={`#EA5500`} />
          <DummyServiceCard
            title={`Creative Director`}
            description={`hire creative leadership to guide your business`}
            icon={`🎨`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section id="startups" className="ServiceSection row">

      <span className="small-caps fw8 red">06</span>
      <h3 className="f2 f1-ns fw7 mt2">Startups</h3>

        <div className="ServiceSection__grid">
          <ServiceCard
            name={`startups`}
            title={`Startups`}
            description={`go from a back-of-the-napkin idea to scalable startup`}
            icon={`🚀`}
            iconPath={``}
            bgColor={`#F03F47`} />

          <ServiceCard
            name={`cto`}
            title={`CTO As A Service`}
            description={`hire technical leadership to grow your business`}
            icon={`💎`}
            iconPath={``}
            bgColor={`#EA5500`} />

          <DummyServiceCard
            title={`Naming`}
            description={`craft a creative and unique business or product name`}
            icon={`📟`}
            iconPath={``}
            bgColor={`#ccc`} />
        </div>

      </section>

      <section className="pv5 mt5-ns bg-coconut-white">
        <div className="row">
            <h2 className="black-80 fw4 mt0">Not sure what you need?</h2>
            <p className="f3 f2-ns fw6 sans-serif salmon lh-solid mt0 measure-narrow">
              No problem, let’s schedule a time to talk about the problem you need solved
            </p>

            <Link
              to="/contact/"
              className="ttu f7 fw8 tc link br1 ph4 pv2 pv3-ns dib mv4 dim white br-pill bg-gradient-pink">
                Schedule a free consultation →
            </Link>

        </div>
      </section>

    </MainWrapper>

  </Layout>
)

export default ServicesPage
